<template>
  <div class="livestream" :class="{
    'go-bg': (isUAMobile && isPotrait) || (isUATablet && isPotrait),
    'go-ld': (isUAMobile && !isPotrait) || (isUATablet && !isPotrait),
  }">
    <!-- <div class="placeholde-image">
      <img src="@/assets/img/PLACE HOLDER-01.png" v-if="isUADesktop == true" />
      <img src="@/assets/img/PLACE HOLDER.png" v-else />
    </div> -->
    <div class="container-lg">
      <div class="row align-items-end">
        <div class="col-12 col-sm-12 col-md-8 col-lg-8">
          <div class="row align-items-end" :class="{
            'justify-content-center':
              (isUAMobile && isPotrait) || (isUATablet && isPotrait),
          }">
            <div class="col-10 col-sm-5">
              <img src="@/assets/img/logo.png" class="img-fluid" alt="logo" />
            </div>
            <div class="col-sm-7">
              <div class="d-flex align-items-end justify-content-end icons" :class="{
                'justify-content-center':
                  (isUAMobile && isPotrait) || (isUATablet && isPotrait),
              }">
                <a href="javascript:void(0)" data-toggle="modal" @click="logActivity('programme')"
                  data-target="#programmeModal" class="mr-2">
                  <img src="@/assets/img/stream/icons/prog.png" class="img-fluid" alt="icon" />
                </a>
                <a href="javascript:void(0)" data-toggle="modal" data-target="#speakerModal"
                  @click="logActivity('speaker')" class="mr-2">
                  <img src="@/assets/img/stream/icons/spe.png" class="img-fluid" alt="icon" />
                </a>
                <a href="#" data-toggle="modal" @click="logActivity('help')" data-target="#helpModal">
                  <img src="@/assets/img/stream/icons/help.png" class="img-fluid" alt="icon" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
          <div class="d-flex align-items-center justify-content-between mb-2 btns-area" v-if="
            isUADesktop ||
            (isUAMobile && !isPotrait) ||
            (isUATablet && !isPotrait)
          ">
            <button class="btn orange-btn" @click="chatHidden = true" :class="{ active: chatHidden }">
              Audience Power
            </button>
            <button class="btn orange-btn" @click="chatHidden = false" :class="{ active: !chatHidden }">
              Chat
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-12 col-md-8 col-lg-8">
          <div class="embed-responsive embed-responsive-16by9 h-100">
            <iframe src="https://player.castr.com/live_aecab7d0ba0011ec91f43bc0d5afe963" class="embed-responsive-item"
              width="100%" height="100%" frameborder="0" scrolling="no" allow="autoplay" allowfullscreen
              webkitallowfullscreen mozallowfullscreen oallowfullscreen msallowfullscreen></iframe>
          </div>
        </div>
        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
          <div class="d-flex align-items-center justify-content-between my-2 btns-area"
            v-if="(isUAMobile && isPotrait) || (isUATablet && isPotrait)">
            <button class="btn orange-btn" @click="chatHidden = true" :class="{ active: chatHidden }">
              Audience Power
            </button>
            <button class="btn orange-btn" @click="chatHidden = false" :class="{ active: !chatHidden }">
              Chat
            </button>
          </div>
          <div
            class="chat-wrapper embed-responsive embed-responsive-16by9 h-100 d-flex flex-column justify-content-start align-items-start">
            <iframe src="https://app.sli.do/event/nfo9qP9b8CevcroiB9kbBx" height="100%" width="100%" frameBorder="0"
              title="Slido" v-if="chatHidden"></iframe>

            <div class="name-wrap d-flex flex-column justify-content-center embed-responsive-item"
              v-if="!chatHidden && !p_name">
              <div class="container-lg text-center">
                <p class="h3 mb-0">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                    class="bi bi-chat-dots" viewBox="0 0 16 16">
                    <path
                      d="M5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                    <path
                      d="m2.165 15.803.02-.004c1.83-.363 2.948-.842 3.468-1.105A9.06 9.06 0 0 0 8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6a10.437 10.437 0 0 1-.524 2.318l-.003.011a10.722 10.722 0 0 1-.244.637c-.079.186.074.394.273.362a21.673 21.673 0 0 0 .693-.125zm.8-3.108a1 1 0 0 0-.287-.801C1.618 10.83 1 9.468 1 8c0-3.192 3.004-6 7-6s7 2.808 7 6c0 3.193-3.004 6-7 6a8.06 8.06 0 0 1-2.088-.272 1 1 0 0 0-.711.074c-.387.196-1.24.57-2.634.893a10.97 10.97 0 0 0 .398-2z" />
                  </svg>
                  Group Chat
                </p>
                <p class="mb-3 small">
                  Please enter your name below to start chat...
                </p>
                <div class="row">
                  <div class="col-sm-10 mx-auto">
                    <div class="input-group mb-3">
                      <input type="text" class="form-control" v-model="name" placeholder="Enter your name"
                        aria-label="Enter your name" aria-describedby="button-addon2" />

                      <div class="input-group-append">
                        <button class="btn btn-outline-success" :disabled="name.length < 2" @click="setName"
                          type="button" id="button-addon2">
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                            class="bi bi-box-arrow-in-right" viewBox="0 0 16 16">
                            <path fill-rule="evenodd"
                              d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0v-2z" />
                            <path fill-rule="evenodd"
                              d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z" />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="chatbox h-100 w-100 embed-responsive-item" v-if="!chatHidden">
              <div class="chatbox__support">
                <div class="chatbox__messages">
                  <div>
                    <p v-if="!messages.length" class="text-center small text-muted">
                      Chat area is empty please type below to start a chat.
                    </p>
                    <div class="messages__item" v-for="(message, index) of messages" :key="index" :class="{
                      'messages__item--visitor': message.uuid != uuid,
                      'messages__item--operator': message.uuid == uuid,
                    }">
                      <div>{{ message.body }}</div>
                      <hr class="mb-0 mt-1" />

                      <span class="small d-inline-block text-truncate text-capitalize" :class="{
                        'text-dark': message.uuid != uuid,
                        'text-white': message.uuid == uuid,
                      }" style="max-width: 150px">
                        {{ message.sender_name }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="chatbox__footer">
                  <input type="text" v-model="body" @keyup.enter="storeMessage()" placeholder="Write a message..." />
                  <emoji-picker class="chatbox__send--footer" @emoji="append" :search="search">
                    <div class="emoji-invoker" slot="emoji-invoker" slot-scope="{ events: { click: clickEvent } }"
                      @click.stop="clickEvent">
                      <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path
                          d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm3.5-9c.83 0 1.5-.67 1.5-1.5S16.33 8 15.5 8 14 8.67 14 9.5s.67 1.5 1.5 1.5zm-7 0c.83 0 1.5-.67 1.5-1.5S9.33 8 8.5 8 7 8.67 7 9.5 7.67 11 8.5 11zm3.5 6.5c2.33 0 4.31-1.46 5.11-3.5H6.89c.8 2.04 2.78 3.5 5.11 3.5z" />
                      </svg>
                    </div>
                    <div slot="emoji-picker" slot-scope="{ emojis, insert }">
                      <div class="emoji-picker">
                        <div class="emoji-picker__search">
                          <input type="text" v-model="search" v-focus />
                        </div>
                        <div>
                          <div v-for="(emojiGroup, category) in emojis" :key="category">
                            <h5>{{ category }}</h5>
                            <div class="emojis">
                              <span v-for="(emoji, emojiName) in emojiGroup" :key="emojiName" @click="insert(emoji)"
                                :title="emojiName">{{ emoji }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </emoji-picker>
                  <a @click="storeMessage" href="javascript:void(0)" class="chatbox__send--footer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                      stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                      class="feather feather-send">
                      <line x1="22" y1="2" x2="11" y2="13"></line>
                      <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
                    </svg></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- speaker modal -->
    <div class="modal fade" id="speakerModal" tabindex="-1" aria-labelledby="speakerModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <div class="modal-body p-0">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                  stroke="#000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                  class="feather feather-x">
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </span>
            </button>

            <!-- <img src="@/assets/img/speaker pop up livestream.png" class="img-fluid w-100" alt="speaker"
              v-if="isUADesktop || isUATablet" />
            <img src="@/assets/img/mobile livestream popup.png" class="img-fluid w-100" alt="speaker"
              v-if="isUAMobile" /> -->
          </div>
        </div>
      </div>
    </div>

    <!-- programme modal -->
    <div class="modal fade" id="programmeModal" tabindex="-1" aria-labelledby="programmeModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <div class="modal-body p-0">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                  stroke="#000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                  class="feather feather-x">
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </span>
            </button>

            <img src="@/assets/img/programme/newchiIno.jpg" class="img-fluid w-100" alt="programme "
              v-if="isUADesktop" />
            <img src="@/assets/img/programme/newchiIno.jpg" class="img-fluid w-100" alt="programme "
              v-if="isUAMobile || isUATablet" />
          </div>
        </div>
      </div>
    </div>

    <!-- help modal -->
    <div class="modal fade" id="helpModal" tabindex="-1" aria-labelledby="helpModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <div class="modal-body p-0">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                  stroke="#000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                  class="feather feather-x">
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </span>
            </button>
            <a href="mailto:conference@chi.sg" target="_blank">
              <img src="@/assets/img/information.png" class="img-fluid w-100" alt="help" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/assets/js/Api";
import Csrf from "@/assets/js/Csrf";
import util from "@/assets/js/util";
import $ from "jquery";

import EmojiPicker from "vue-emoji-picker";
import { createLogger, mapState } from "vuex";
import UAParser from "ua-parser-js";

export default {
  name: "livestream",
  components: {
    EmojiPicker,
  },

  data() {
    return {
      chatHidden: false,
      isPotrait: false,
      isUADesktop: false,
      isUAMobile: false,
      isUATablet: false,
      body: "",
      messages: [],
      search: "",
      attendance_interval: false,
      uuid: "",
      p_name: "",
      name: "",
    };
  },
  methods: {
    setName() {
      this.p_name = this.name;
      console.log(
        "%clivestage.vue line:282 this.name",
        "color: #007acc;",
        this.name
      );
      localStorage.setItem("p_name", this.name);
    },
    append(emoji) {
      this.body += emoji;
    },
    getOrientation() {
      var mql = window.matchMedia("(orientation: portrait)");

      if (mql.matches) {
        this.isPotrait = true;
      } else {
        this.isPotrait = false;
      }

      mql.addListener(function (m) {
        if (m.matches) {
          this.isPotrait = true;
        } else {
          this.isPotrait = false;
        }
      });
    },
    getUid() {
      let r =
        (Math.random() + 1).toString(36).substring(2) +
        (Math.random() + 1).toString(36).substring(2);

      if (localStorage.getItem("rand")) {
        this.uuid = localStorage.getItem("rand");
        return this.uuid;
      }

      localStorage.setItem("rand", r);
      this.uuid = r;
      return r;
    },
    getPname() {
      if (localStorage.getItem("p_name")) {
        this.p_name = localStorage.getItem("p_name");
      }
    },
    react() {
      return;
      Echo.join(`trigger-change`).whisper("reaction", {
        type: "heart",
      });
    },
    append(emoji) {
      this.body += emoji;
    },
    getChannelId() {
      Api.post("session-channel", { session: "main-audi" }).then((res) => {
        console.log("%clobby.vue line:88 res", "color: #007acc;", res);
        this.channel = res.data.channel;
        this.watchSessionMessages();
        this.getMessages();
      });
    },
    storeMessage() {
      if (!this.body || !this.channel.id) return;

      this.pushSelfMessage();
      if (!this.uuid) alert("uid not generated");
      if (!this.p_name) alert("name not set");
      let body = this.body;
      this.body = "";
      Api.post("session-message", {
        channel_id: this.channel.id,
        body: body,
        uuid: this.uuid,
        name: this.p_name,
      }).then((res) => { });
    },
    watchSessionMessages() {
      let self = this;
      console.log(
        "%cauditorium.vue line:321 this.channel.id",
        "color: #007acc;",
        this.channel.id
      );
      Echo.channel(`new-session-message-${this.channel.id}`).listen(
        "NewSessionMessage",
        (e) => {
          console.log(e);
          if (e.message.uuid != self.uuid) self.pushMessage(e.message);
        }
      );
    },
    getMessages() {
      Api.get(`session-messages?channel_id=${this.channel.id}`).then((res) => {
        this.messages = res.data.messages;
      });
    },
    pushMessage(message) {
      this.react();
      this.messages.push(message);
    },
    pushSelfMessage() {
      this.messages.push({
        uuid: this.uuid,
        sender_name: this.p_name,
        body: this.body,
        channel_id: this.channel.id,
      });
    },
    joinChannel() {
      let self = this;

      Echo.join(`auditorium`).here((users) => {
       console.log(users.length)
      });
      // setTimeout(() => {
      //   self.setAttendance();
      // }, 1000);
    },
    leaveChannel() {
      if (this.attendance_interval) clearInterval(this.attendance_interval);

      Echo.leave(`auditorium`);
    },
    broadcastReaction(reaction) {
      Echo.private("new-message-1").whisper("reaction", {
        reaction: reaction,
      });
    },
  },
  computed: mapState(["user"]),

  mounted() {
    let self = this;
    setTimeout(() => {
      self.getUid();
    }, 1000);
    this.getPname();
    this.getChannelId();

    $(window).bind("orientationchange", function (event) {
      location.reload(true);
    });

    let parser = new UAParser();
    let parser_result = parser.getResult();

    if (parser_result.device.type == "mobile") {
      this.isUAMobile = true;
    } else if (parser_result.device.type == "tablet") {
      this.isUATablet = true;
    } else {
      this.isUADesktop = true;
    }

    this.getOrientation();
    this.joinChannel();
  },
};
</script>

<style lang="scss" scoped>
$chatbg: #f0523d;

.livestream {
  min-height: 100vh;
  background: url("../assets/img/stream/bg.png") no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 28px;

  .placeholde-image {
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;

    img {
      width: 100%;
      height: 100vh;
    }
  }

  & .icons {
    & a {
      max-width: 100px;

      &:nth-child(1) {
        max-width: 115px;
      }
    }
  }

  & .btns-area {
    & .btn {
      box-shadow: 9px 17px 15px -3px rgba(0, 0, 0, 0.1),
        -13px 10px 15px -3px rgba(0, 0, 0, 0.1);

      &.active {
        color: #bf1f2f;
        font-weight: 700;
      }
    }
  }

  .orange-btn {
    border-radius: 40px;
    color: #fff;
    font-weight: 600;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    border: 3px solid #f0523d;
    text-transform: uppercase;
    transition: 0.3s;
    scale: 1;
    background: rgb(245, 136, 70);

    background: -moz-linear-gradient(76deg,
        rgba(245, 136, 70, 1) 0%,
        rgba(249, 166, 76, 1) 50%,
        rgba(242, 105, 63, 1) 100%);
    background: -webkit-linear-gradient(76deg,
        rgba(245, 136, 70, 1) 0%,
        rgba(249, 166, 76, 1) 50%,
        rgba(242, 105, 63, 1) 100%);
    background: linear-gradient(76deg,
        rgba(245, 136, 70, 1) 0%,
        rgba(249, 166, 76, 1) 50%,
        rgba(242, 105, 63, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f58846", endColorstr="#f2693f", GradientType=1);

    &:hover {
      transform: scale(1.1);
      color: #fff;
    }
  }

  & .name-wrap {
    height: 100%;
    width: 100%;
    z-index: 1;
    background: rgba(255, 255, 255, 0.4);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(12px);
    border: 1px solid rgba(255, 255, 255, 0.18);
  }

  #speakerModal,
  #programmeModal,
  #helpModal {
    & .modal-body {
      position: relative;

      & .close {
        position: absolute;
        top: 10px;
        right: 10px;
      }
    }
  }

  & .chat-wrapper {

    // Chat ui
    .chatbox__support {
      display: flex;
      flex-direction: column;
      background: #eee;

      z-index: -123456;
      transition: all 0.5s ease-in-out;
    }

    .chatbox__messages {
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      flex-direction: column-reverse;
      height: 100%;
    }

    .messages__item {
      background: #134e5c;
      max-width: 60.6%;
      width: fit-content;
    }

    .messages__item--operator {
      margin-left: auto;
    }

    .messages__item--visitor {
      margin-right: auto;
    }

    .chatbox__footer {
      position: sticky;
      bottom: 0;
    }

    .chatbox__support {
      background: #f9f9f9;
      height: 100%;
      width: 100%;
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    }

    .chatbox__messages {
      padding: 40px 10px 20px 10px;
    }

    .messages__item {
      margin-top: 10px;
      background: #e0e0e0;
      padding: 8px 12px;
      max-width: 70%;
      font-size: 0.8rem;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
    }

    .messages__item--visitor,
    .messages__item--typing {
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
    }

    .messages__item--operator {
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 0;
      background: $chatbg;
      color: white;
    }

    .chatbox__footer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 10px 20px;
      background: $chatbg;
      box-shadow: none;
      margin-top: 5px;
      position: relative;
    }

    .chatbox__footer input {
      border: none;
      padding: 10px 10px;
      border-radius: 30px;
      font-size: 0.8rem;
      flex: 1;

      &:focus-visible {
        outline: none;
      }
    }

    .chatbox__send--footer {
      color: white;
      padding-left: 10px;
    }

    .chatbox__button button,
    .chatbox__button button:focus,
    .chatbox__button button:visited {
      padding: 10px;
      background: white;
      border: none;
      outline: none;
      border-top-left-radius: 50px;
      border-top-right-radius: 50px;
      border-bottom-left-radius: 50px;
      border-bottom-right-radius: 50px;
      box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
      cursor: pointer;
    }

    .emoji-invoker {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      cursor: pointer;
      transition: all 0.2s;
    }

    .emoji-invoker:hover {
      transform: scale(1.1);
    }

    .emoji-invoker>svg {
      fill: #ffffff;
    }

    .emoji-picker {
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 1;
      font-family: inherit;
      border: 1px solid #ccc;
      width: 15rem;
      height: 20rem;
      overflow: scroll;
      padding: 1rem;
      box-sizing: border-box;
      border-radius: 0.5rem;
      background: #fff;
      box-shadow: 1px 1px 8px #c7dbe6;
    }

    .emoji-picker__search {
      display: flex;
      margin-bottom: 10px;
    }

    .emoji-picker__search>input {
      flex: 1;
      border-radius: 10rem;
      border: 1px solid #ccc;
      padding: 0.5rem 1rem;
      outline: none;
    }

    .emoji-picker h5 {
      margin-bottom: 0;
      color: #b1b1b1;
      text-transform: uppercase;
      font-size: 0.8rem;
      cursor: default;
    }

    .emoji-picker .emojis {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .emoji-picker .emojis:after {
      content: "";
      flex: auto;
    }

    .emoji-picker .emojis span {
      padding: 0.2rem;
      cursor: pointer;
      border-radius: 5px;
    }

    .emoji-picker .emojis span:hover {
      background: #ececec;
      cursor: pointer;
    }
  }
}

.go-bg {
  background: url("../assets/img/stream/mobile-bg.png") no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
  padding: 60px 0 20px 0;

  & .chat-wrapper {
    height: 400px !important;
  }
}

.go-ld {
  & .btns-area {
    & .btn {
      font-size: 0.6rem !important;
    }
  }
}
</style>
